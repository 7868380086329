import { IAttachmentDTO } from "./Attachment";
import { IMessageReceiversDTO } from "./MessageDTO";
import { IStringSelectOption } from "./Misc";
import { Opportunity } from "./Opportunity";
import { ISimplePerson } from "./Person";

export interface IBaseInteractionDTO {
  id: string
  text?: string
  interactionType?: string
  created: Date | string
  deleted?: Date | string
};

export enum InteractionTypes {
  EXTERNAL_MESSAGE = "externalMessage",
  MESSAGE = "message",
  NOTE = "note"
};

export enum InteractionRequestTypes {
  EXTERNAL_MESSAGE = "external_messages",
  MESSAGE = "messages",
  NOTE = "note"
};

export enum MessageSource {
  EMAIL = "email",
  SMS = "sms",
  TORRE_MESSENGER = "messenger",
  WHATSAPP = "whatsapp"
};

export interface IMessageContextDTO {
  vendorMessageId: string,
  received: string,
  subject?: string,
  notificationId?: number
};

export const messageSourcesAsSelectOptions: IStringSelectOption[] = [
  { text: "Email", value: MessageSource.EMAIL },
  // { text: "SMS", value: MessageSource.SMS },
  { text: "Torre messenger", value: MessageSource.TORRE_MESSENGER },
  { text: "WhatsApp", value: MessageSource.WHATSAPP }
];

export interface IMessageDTO extends IBaseInteractionDTO {
  roomId: string
  modified?: Date | string
  receivers: IMessageReceiversDTO[]
  poster: ISimplePerson
  interactionType?: InteractionTypes
  notificationSent?: boolean
  source?: MessageSource
  attachment?: IAttachmentDTO,
  context?: IMessageContextDTO
};

export interface INote extends IBaseInteractionDTO {
  lastUpdated: string;
  opportunityRefId?: string;
  opportunity?: Opportunity;
};

export type IInteraction = IMessageDTO | INote;

export interface ISentMessageLogDTO {
  sender: ISimplePerson,
  taskId: number,
  notificationId: number
};

export interface IRoomDTO {
  id: string
  participants: ISimplePerson[],
  created: string
};

export enum RoomSelection {
  DIRECT = "direct",
  ANY = "any"
}

export const getMessageSource = (source: MessageSource | undefined): MessageSource => {
  const validSources = new Set(Object.values(MessageSource));

  if (source) {
    return validSources.has(source) ? source : MessageSource.TORRE_MESSENGER;
  } else {
    return MessageSource.TORRE_MESSENGER;
  }
};
